<template>
  <div class="content-wrap">
    <div class="content-select">
      <div>
        <label>商品名称</label>
        <el-input v-model="filter.merchandiseName" placeholder="请输入商品名称"
                  @input="querySearchAsync"></el-input>
      </div>
      <div class="items">
        <el-button type="primary" @click="handleQuery(1)">查询</el-button>
        <el-button type="primary" @click="handleShowEdit">新增</el-button>
      </div>
    </div>
    <div class="content-main">
      <div class="content-table">
        <el-table :data="records" highlight-current-row
                  ref="singleTable"
                  v-loading="tableLoading"
                  border
                  stripe
                  :header-cell-style="handleHeaderCellStyle" max-height="400px"
                  style="width: 100%" @current-change="handleCurrentChange">
          <el-table-column prop="merchandiseName" label="商品名称" width="140"></el-table-column>
          <el-table-column prop="specification" label="规格型号" width="120"></el-table-column>
          <el-table-column prop="unit" label="单位" width="80"></el-table-column>
          <el-table-column prop="price" label="单价" width="80"></el-table-column>
          <el-table-column prop="taxRate" label="税率" width="80" :formatter="fmtTaxRate"></el-table-column>
          <el-table-column prop="taxSign" label="含税标志" width="80" :formatter="fmtTaxSign"></el-table-column>
          <el-table-column prop="taxClassCode" label="税收分类编码"></el-table-column>
        </el-table>
      </div>
      <div class="content-page">
        <el-pagination
          background
          @current-change="handleQuery"
          :current-page.sync="filter.page"
          :page-size="filter.size"
          layout="total, prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 新增商品 -->
    <el-dialog append-to-body title="新增" :visible.sync="editVisible" :show-close="false" :destroy-on-close="true">
      <merchandise-edit v-if="editVisible" :merchandise="merchandise" :is-retail="true"
                        :content="taxPreferentialContents"
                        scene="Billing"
                        @edit-finish="handleQuery(1)"
                        @handleCancelEditDialog="handleCloseEdit"></merchandise-edit>
    </el-dialog>
  </div>
</template>

<script>
import { tableStyle } from '@/util/mixins'
import { pagingMerchandise } from '@/service/merchandise'
import MerchandiseEdit from '@/views/system/merchandise/MerchandiseEdit'

export default {
  name: 'MerchandiseListDialog',
  mixins: [tableStyle],
  components: { MerchandiseEdit },
  data () {
    return {
      filter: {
        merchandiseName: '',
        resetProp: '',
        page: 1,
        size: 15
      },
      total: 0,
      tableLoading: false,
      editVisible: false,
      records: [],
      timeout: null,
      taxPreferentialContents: [],
      merchandise: {
        orgId: null,
        uniqueCode: undefined,
        merchandiseName: '',
        taxRate: null,
        merchandiseCategory: '',
        taxClassName: '',
        isTaxPreferential: '',
        taxPreferentialContent: '',
        taxTypeCodeVersion: '',
        taxClassCode: '',
        taxRateModel: ''
      }
    }
  },
  created () {
    this.getDicts('o-merchandise-tax').then(res => this.taxPreferentialContents = res.data)
    this.handleQuery()
  },
  methods: {
    // 实时远程查询
    querySearchAsync () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.handleQuery(1)
      }, 1000)
    },
    // 商品名称模糊查询
    async handleQuery (val) {
      if (val) this.filter.page = val

      this.tableLoading = true
      this.filter["orgId"] = this.$store.state.invoiceIssue.CurrentOrganization
      this.filter.resetPropsByTaxpayer = 'Y'
      const { success, data } = await pagingMerchandise(this.filter)
      this.tableLoading = false
      if (success) {
        this.records = data.records
        this.total = data.total
      }
    },
    // 选定商品
    handleCurrentChange (row) {
      if (row) this.$emit('handleCurrentGoods', row)
      this.setCurrent()
    },
    // 清除当前选中
    setCurrent (row) {
      this.$refs.singleTable.setCurrentRow(row)
    },
    handleShowEdit(){
      this.merchandise.orgId = this.$store.state.invoiceIssue.CurrentOrganization
      this.editVisible = true
    },
    handleCloseEdit () {
      this.editVisible = false
      this.merchandise = {
        uniqueCode: undefined,
        merchandiseName: '',
        taxRate: null,
        merchandiseCategory: '',
        taxClassName: '',
        isTaxPreferential: '',
        taxPreferentialContent: '',
        taxClassCode: '',
        taxRateModel: ''
      }
    },
    // 格式化
    fmtTaxSign (row) {
      return row.taxSign ? '是' : '否'
    },
    fmtTaxRate (row) {
      if (row.taxPreferentialContent == '免税' || row.taxPreferentialContent == '不征税') {
        return row.taxPreferentialContent
      }
      return row.taxRate ? row.taxRate * 100 + '%' : ''
    }
  }
}
</script>

<style scoped lang="scss">
  .content-wrap {
    padding: 0 24px;
    width: auto;
  }

  .content-select {
    display: flex;
    flex-direction: row;
    box-shadow: none;
    justify-content: space-between;

    .items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    label {
      width: 80px;
      margin-right: 16px;
    }
  }

  .content-main {
    margin: 0;
    padding: 24px 0;
    width: 760px;
  }

  .content-table {
    width: 100%
  }

</style>
